import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Carousel from '../../../Carousel';
import { css } from '@emotion/css';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useClips } from '@bamboo/core-lib/src/repo/clips';
import HiddenComponent from '@bamboo/core-lib/components/HiddenComponent';
import { useRouter } from 'next/router';
import type { Playlist } from '@bamboo/core-lib/api/types';
import type { CarouselItem } from '../../../Carousel';
import type { Theme } from '@/themes/mui/mui.theme';

import { TextContainer, Title } from './CategoriesCarousel.styles';

const styles = (theme: Theme) => ({
  image: {
    aspectRatio: '16/9',

    maxHeight: '350px',
  },
  itemContainer: {},

  root: {},

  listItem: {},
});

type CategoriesCarouselProps = {
  type: '1' | '2' | '3';
};

export const CategoriesCarousel = ({ type }: CategoriesCarouselProps) => {
  const theme = useTheme();
  const router = useRouter();
  const classes = styles(theme);

  const { playlists } = useClips();

  const onClick = useCallback(
    (index: number, e?: React.MouseEvent<HTMLDivElement>) => {
      e?.preventDefault?.();
      e?.stopPropagation?.();
      const item = playlists[index];
      router.push(`/playlists/${item.slug}`);
    },
    [playlists, router]
  );

  const carouselItemList = useMemo(() => {
    if (playlists.length === 0) return [];

    const totalItems = playlists.length;

    const formattedItems = playlists.map((item) => {
      return {
        image: item.image,
        label: <CarouselLabel item={item} />,
        name: item.name,
        slug: item.slug,
      } as CarouselItem;
    });

    if (type === '1') {
      return formattedItems.slice(0, Math.round(totalItems / 3));
    }

    if (type === '2') {
      return formattedItems.slice(
        Math.round(totalItems / 3),
        Math.round(totalItems / 3) * 2
      );
    }

    if (type === '3') {
      return formattedItems.slice(Math.round(totalItems / 3) * 2, 9);
    }

    return formattedItems;
  }, [playlists, type]);

  return (
    <Carousel
      autoplay
      infinite
      centerMode
      swipeToSlide
      autoplaySpeed={4000}
      list={carouselItemList}
      onClickItem={onClick}
      classes={{
        image: css(classes.image),
        itemContainer: css(classes.itemContainer),
        root: css(classes.root),
        listItem: css(classes.listItem),
      }}
      slidesToShowInBreakpoints={{
        xl: 3,
        sm: 1.1,
      }}
    />
  );
};

const CarouselLabel = ({ item }: { item: Playlist }) => {
  return (
    <TextContainer>
      <Title>{item.name}</Title>
    </TextContainer>
  );
};
