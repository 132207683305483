import { styled } from '@mui/material/styles';

const min1536 = '@media (min-width:1536px)';
const max930 = '@media (max-width:930px)';
const max1100 = '@media (max-width:1100px)';

export const Section = styled('section')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'between',
  alignItems: 'center',
  gap: '20px',
  marginTop: '60px',
  padding: '20px 85px',

  [max1100]: {
    paddingInline: '40px',
  },

  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    marginBottom: '5px',
    paddingTop: '5px',
  },
}));

export const Title = styled('p')(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '14.63px',
  fontWeight: 600,
  color: '#3A3A3A',
  paddingLeft: '11px',
  borderLeft: '2px solid #969696',
  width: '100%',
  maxWidth: '170px',
  [theme.breakpoints.down('lg')]: {
    borderLeft: '0',
    paddingLeft: '0',
    paddingTop: '11px',
    borderTop: '2px solid #969696',
    textAlign: 'center',
    display: 'none',
  },
}));

export const Partners = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '10px',
  marginLeft: '200px',

  [max1100]: {
    justifyContent: 'center',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    columnGap: '30px',
    overflow: 'hidden',
    height: '160px',
    marginLeft: 0,
  },
}));

export const ContainerTextMobile = styled('div')(({ theme }) => ({
  display: 'none',

  [max930]: {
    marginTop: '60px',
    display: 'table',
    textAlign: 'center',
    color: '#414141',
  },
}));

export const H1TextMobile = styled('div')(({ theme }) => ({
  fontSize: '30px',
  lineHeight: '30px',
  fontWeight: '500',
  color: '#000',
}));

export const H4TextMobile = styled('h4')(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '22px',
  fontWeight: '400',
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
  },
}));
