import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from 'next/image';

// const max1500 = '@media (max-width:1500px)';
const max1441 = '@media (min-width:1441px)';
const max1180 = '@media (max-width:1180px)';
const max780 = '@media (max-width:780px)';
const max580 = '@media (max-width:580px)';

export const Section = styled('section')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '20px',
  marginTop: '130px',
  marginBottom: '140px',
  paddingInline: '120px',
  // paddingInline: '250px',

  p: {
    margin: 0,
  },

  [max1441]: {
    gap: '50px',
    paddingInline: '230px',
  },

  [max1180]: {
    paddingInline: '60px',
  },

  [max780]: {
    paddingInline: '20px',
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    margin: '80px 0 60px',
    gap: '40px',
    paddingInline: '20px',
  },
}));

export const LeftSide = styled('aside')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  width: '100%',
  maxWidth: '374px',
  height: '100%',
  marginTop: '30px',

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    marginTop: '0px',
    gap: '15px',
  },
}));

export const Title = styled('p')(({ theme }) => ({
  fontSize: '40px',
  lineHeight: '40px',
  fontWeight: 500,
  color: '#000',

  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    lineHeight: '40px',
  },

  [max780]: {
    fontSize: '28px',
    lineHeight: '28px',
  },
}));

export const ColoredText = styled('span')(({ theme }) => ({
  fontSize: '30px',
  lineHeight: '30px',
  fontWeight: 500,
  color: '#414141',

  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    color: '#4b9ed6',
  },
}));

export const Description = styled('p')(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 400,
  color: '#3A3A3A',

  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    fontSize: '12px',
    color: '#000',
  },

  [max780]: {
    fontSize: '12px',
    lineHeight: '20px',
    padding: '0 40px',
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#000',
  color: '#fff',
  borderRadius: '42px',
  width: '100%',
  maxWidth: '185px',

  '&:hover': {
    backgroundColor: '#000',
    opacity: 0.8,
  },

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const RightSide = styled('aside')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  img: {
    objectPosition: 'center',
  },

  [max580]: {
    width: '70%',
  },
}));

export const StyledImage = styled(Image)(({ theme }) => ({
  objectFit: 'cover',
  objectPosition: 'center',

  [theme.breakpoints.down('md')]: {
    width: '50px',
    height: '50px',
  },

  [max780]: {
    width: '50px',
    height: '50px',
  },

  [max580]: {
    width: '32px',
    height: '32px',
  },
}));

export const StyledAncineImage = styled(Image)(({ theme }) => ({
  borderRight: 'solid 1px rgb(231, 231, 231)',
  paddingRight: '60px',
  marginRight: '60px',

  [theme.breakpoints.down('md')]: {
    width: '64px',
    height: 'auto',
    paddingRight: '30px',
    marginRight: '30px',
  },

  [max780]: {
    width: '100px',
    height: '65px',
  },

  [max580]: {
    width: '64px',
    height: 'auto',
    paddingRight: '30px',
    marginRight: '30px',
  },
}));

export const ContainerTvImage = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: '5vw',

  [max580]: {
    gap: '6vw',
  },
}));
